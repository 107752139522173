.b-footer
  min-width: 320px
  margin-top 9px
  background-color cMainBlack
  box-sizing: border-box
  font: 400 16px/20px "Montserrat", sans-serif
  &__menu
    display: flex;
    flex-wrap: wrap;
  &__menu-column
    width: 100%;
  &__wrapper
    box-sizing: border-box
    width: 100%;
    padding 30px 16px 40px 16px
  &__menu-link
    padding 10px 0
    width 100%
    display block
    color cTextWhite!important
  &__contacts
    margin-top 40px
    font: 400 16px/20px "Roboto", sans-serif
  &__socials
    display flex
    margin-top 40px
  &__bottom-block
    margin-top 40px
    font: 400 14px/19.6px "Roboto", sans-serif
    color cTextGray

.b-footer-contacts
  &__phone,&__mail
    color cTextWhite!important
    display inline-block
    margin-top 20px
    transition: opacity  .3s ease
    &:hover
      opacity: .7
      transition: opacity  .2s ease
  &__contact-container
    width: 100%

.b-footer-socials
  &__item
    width 48px
    height 48px
    background-color cWhiteFon
    border-radius 50%
    position relative
    margin-right 24px
    display flex
    align-items center
    justify-content center
  &__item-img
    width 24px
    height 24px
    &--tg
      background: url('../img/icons/tg-footer.svg') no-repeat
      background-size contain
    &--rut
      background: url('../img/icons/rutube-footer.svg') no-repeat
      background-size contain      
    &--vk
      background: url('../img/icons/vk-footer.svg') no-repeat
      background-size contain
    &--yout
      background: url('../img/icons/yout-footer.svg') no-repeat
      background-size contain
    &--dzen
      background: url('../img/icons/dzen-footer.svg') no-repeat
      background-size contain

.b-footer-bottom-block
  &__text
    margin-bottom 20px
    display flex
    flex-direction column
  &__text-link
    display inline
    color cTextGray!important

@media screen and (min-width: tWidth)
  .b-footer
    display flex
    flex-wrap wrap
    &__wrapper
      display flex
      flex-wrap wrap
      padding 31px 22px 53px 22px
    &__menu
      width 60%
      display: flex;
      flex-wrap: nowrap;
    &__menu-column
      width: 50%;
    &__menu-link
      width fit-content
    &__contacts
      width: calc(40% - 100px)
      margin-top: -12px;
      margin-left: 100px
    &__socials
      width 100%
      margin-top: 34px
    &__bottom-block 
      margin-top 44px
      display flex
  .b-footer-socials
    &__item
      margin-right 16px
      cursor: pointer
      transition: opacity  .3s ease
      &:hover
        opacity: 0.7
        transition: opacity  .2s ease
  .b-footer-bottom-block
    &__text
      margin-right 46px
      
@media screen and (min-width: wWidth)
  .b-footer
    &__menu
      width: 42%
    &__contacts
      margin-left: 126px;
      margin-right: 30px;
      width: 22%
    &__wrapper
      padding 31px 0px 53px 0px
      display flex
      flex-wrap wrap
      width  1460px
      margin 0 auto
    &__socials
      width: 25%
      margin-top: 9px
    &__menu-link
      transition: opacity .3 ease
      &:hover
        transition: opacity .2 ease
        opacity  .7
    &__bottom-block
      width: 100%
      padding-right: 144px
      margin-top: 49px
      justify-content: space-between
  .b-footer-bottom-block
    &__text
      flex-direction: row

  .b-footer-socials
    &__item
      &:first-child
        margin-left: 18px