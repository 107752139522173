html, body
    height: 100%
    word-break: break-word

html
    background: cWhite
    --height-block: 400px

body
    min-width: 320px
    word-wrap: break-word
    display: flex
    flex-direction: column
    overflow-y: scroll
.page
    flex-grow: 1

.main-html
  min-width: 320px;