.b-card
  &__item
    display: flex
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
    border-radius: 12px
    background-color: cWhiteFon
    margin-bottom 20px
    height: 204px
  &__img-container
    position relative 
    width: 47.56%
    display: flex
    overflow: hidden
  &__img
    object-fit: cover
    width: 100%
  &__info-container
    width: 52.44%
    padding: 20px 16px
    display:  flex
    flex-direction: column
    justify-content: space-between
    box-sizing: border-box
  &__title
    color cMainBlack
    font: 700 16px/22.4px "Roboto", sans-serif
    display: -webkit-box
    -webkit-line-clamp: 6
    -webkit-box-orient: vertical
    overflow: hidden
  &__text
    color cMainBlack
    display: none
  &__date
    font: 400 14px/19.6px "Roboto", sans-serif
    color cTextGray

@media screen and (min-width: tWidth)
  .b-card
    &__img-container
      width: 46.4%
      border-radius: 12px 0px 0px 12px
      display: flex
    &__item
      height: 272px!important
    &__info-container
      padding: 26px 16px 20px 21px
@media screen and (min-width: dWidth)
  .b-card
    &__title
      font: 700 18px/24px "Roboto", sans-serif
      -webkit-line-clamp: 5
    &__text
      display: inline
      display: -webkit-box
      -webkit-line-clamp: 4
      -webkit-box-orient: vertical
      overflow: hidden
      font: 400 16px/22.4px "Roboto", sans-serif
    &__item
      margin-right: 28px
      width: calc(33.333% - 18.666px)
      cursor pointer
      transition: box-shadow .3s ease
      box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
      &:hover
        color cMainBlack
        transition: box-shadow .2s ease
        box-shadow: 0px 2px 24px rgba(51, 51, 51, .12)
      &:last-child
        margin-right 0
    &__img-container
      width: 49.4%

@media screen and (min-width: wWidth)
  .b-card
    &__title
      font: 700 18px/24px "Roboto", sans-serif
      -webkit-line-clamp: 5
    &__text
      display: inline
      display: -webkit-box
      -webkit-line-clamp: 4
      -webkit-box-orient: vertical
      overflow: hidden
      font: 400 16px/22.4px "Roboto", sans-serif    
    &__img-container
      width: 49.4%
    &__item
      height: 300px !important