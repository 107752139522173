.b-parthners
    padding: 61px 25px
    &__title
      font: 700 24px/36px "Montserrat", sans-serif
      text-align: center
      margin-bottom: 29px
    &__items-container
      display: flex
      flex-wrap: wrap
    &__img-container
      max-height: 70px
      width: calc(33.3% - 10px)
      margin-right: 15px
      margin-bottom: 16px
      &:nth-child(3n)
        margin-right: 0
    &__img
      width: 100%
      height: 100%
      object-fit: contain
      max-width: 120px;
@media screen and (min-width: tWidth)
  .b-parthners
    padding: 63px 74px 44px 73px
    &__title
      margin-bottom: 31px
      font: 700 30px/36px "Montserrat", sans-serif
    &__items-container
      display: flex
      flex-wrap: wrap
      justify-content: center
    &__img-container
      width: calc(20% - 21.6px)
      margin-bottom: 27px
      margin-left: 12px
      max-height: 70px
      display: flex
      justify-content: center
      margin-right: 12px
      &:nth-child(3n)
        margin-right: 24px
      &:nth-child(5n)
        margin-right: 0!important
      &:nth-child(5n+1)
        margin-left: 0!important
    &__img
      width: calc(100% - 20px);
@media screen and (min-width: dWidth)
  .b-parthners
    &__title
      margin-bottom: 48px
      font: 700 36px/44px "Montserrat", sans-serif
    &__img-container
      height:  auto
      max-height:  100px
      max-width: 140px
      
@media screen and (min-width: wWidth)
  .b-parthners
    padding:  143px 20px 96px 20px
    &__img-container
      margin-right 0!important
      width:  calc(11.1% - 10.66666px)
      &:nth-child(5n+1)
        margin-left: 12px!important
      &:first-child
        margin-left: 0px!important