.b-community
  padding:  40px 24px 58px 24px
  &__questions-block,&__lection-block
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08);
    border-radius: 12px;
    margin-bottom:  20px
    overflow: hidden
    display: block
  &__title
    color cMainBlack
    font: 700 20px/28px "Montserrat", sans-serif
    &--short
      width: 70%
      margin-bottom: 24px
  &__button
    width: 187px
    padding: 15px 30px;
    display block
.b-community-questions
  display: block;
  &__img-container
    position: relative
    height: 0
    padding-top:  67.38%
  &__date
    padding: 20px 34px 20px 15px
  &__img
    position: absolute
    top 0
    object-fit: cover
    left: 0
    width:  100%
    height:  100%
.b-community-lection
  &__img-container
    position: relative
    height: 0
    padding-top:  67%
  &__img
    position: absolute
    top 0
    left: 0
    width:  100%
    height:  100%
    object-fit: cover;
  &__date
    padding: 20px 34px 20px 15px
@media screen and (min-width: tWidth)
  .b-community
    padding:  40px 24px 60px 24px
    &__questions-block,&__lection-block
      display: flex
      margin-bottom: 23px
    &__title
      margin-bottom: 32px
      &--short
        font: 700 24px/36px "Montserrat", sans-serif
        width: 90%
    &__button
      padding: 20px 40px 
      display inline-block
      width: auto
      font: 700 18px/20px "Montserrat", sans-serif
  .b-community-questions
    &__img-container
      width: 64.6%
      padding-top: 41.7%
    &__date
      padding: 60px 69px 20px 24px
      width: 51.77777%
  .b-community-lection
    &__img-container
      width: 64.6%
      padding-top: 30%
      margin-bottom: 0px;
    &__date
      padding: 60px 69px 20px 24px
      width: 51.77777%
@media screen and (min-width: dWidth)
  .b-community
    display: flex
    flex-wrap: wrap;
    justify-content: space-between;
    &__questions-block
      width: 75%
      cursor pointer
      transition: box-shadow .3s ease
      &:hover
        color cMainBlack
        transition: box-shadow .2s ease
        box-shadow: 0px 2px 24px rgba(51, 51, 51, 0.12)
    &__lection-block
      flex-direction: column
      width: calc(25% - 28px)
      transition: box-shadow .3s ease
      cursor pointer
      &:hover
        color cMainBlack
        transition: box-shadow .2s ease
        box-shadow: 0px 2px 24px rgba(51, 51, 51, 0.12)
      &--wide
        width: calc(50% - 14px);
        .b-community-lection__img-container
          height: 260px;
    &__title
      margin-bottom: 0
      font: 700 18px/24px "Montserrat", sans-serif
      &--short
        margin-bottom: 40px
        font: 700 24px/36px "Montserrat", sans-serif
  .b-community-questions
    &__date
      padding 60px 64px 21px 27px
    &__img-container
      padding: 0
      height: 300px
      display: flex
      width: 75.6%
    &__img
      object-fit: cover
      position relative
  .b-community-lection
    &__img-container
      padding: 0
      height: 214px
      display: flex
      width: 100%
    &__img
      object-fit: cover
      position relative
    &__date
      padding:  17px 24px 20px 17px
      width: 100%

@media screen and (min-width: wWidth)
  .b-community
    padding:  82px 20px 96px 20px
