.b-news-list
  padding: 30px 16px 60px 16px
  &__title
    margin-bottom: 16px
  &__button 
    display block
    width 157px
    padding: 11px 20px
    font: 700 16px/18px "Montserrat", sans-serif
    margin-bottom 24px
  &__slider
    display none

@media screen and (min-width: tWidth)
  .b-news-list
    padding: 19px 24px 96px 24px
    &__slider
      display block
    &__cards
      display: none
    &__title, &__button
      display inline-block
      margin-bottom 31px
    &__button
      margin-left: 18px
  .b-news-list-slider
    &__container 
      position relative

@media screen and (min-width: dWidth)
  .b-news-list
    &__slider
      display none
    &__cards
      display: block
    &__button
      padding: 11px 20px
      font: 700 16px/18px "Montserrat", sans-serif
    &__cards
      display: flex

@media screen and (min-width: wWidth)
  .b-news-list  
    padding: 22px 20px 100px 20px
    &__title, &__button
      margin-bottom: 39px
