.b-mobile-menu-submenu
  &__item
    &:first-child
      .b-mobile-menu-submenu__link
        padding-top 12px
    &:last-child
      .b-mobile-menu-submenu__link
        padding-bottom 21px
  &__link
    display block
    color cMainBlack!important
    padding 10px 40px 10px 40px
    font: 400 16px/22.4px "Roboto", sans-serif

@media screen and (min-width: tWidth)
  .b-mobile-menu-submenu
    &__item
      &:first-child
        .b-mobile-menu-submenu__link
          padding-top 14px
      &:last-child
        .b-mobile-menu-submenu__link
          padding-bottom 24px
