.b-mobile-menu-item
  &__item
    border-bottom 1px solid cBorder 
  &__link
    display inline-block
    padding 12px 6px 11px 0
    position relative
    &._open
      color cGreenActive
      &:before
        background url("../img/icons/menu-arrow-active.svg") no-repeat
        transition transform .2s ease
        transform rotate(180deg)
  &__arrow-container
    padding-left: 6px
    transition: transform .3s ease
    display inline
  &__arrow
      transform-origin: center center
      transition: transform .3s ease
  &__submenu
    max-height: 0
    opacity 0
    visibility: hidden
    transition max-height .3s ease, opacity .3s ease
    &._open
      max-height: 0
      visibility: visible
      max-height 400px
      opacity 1
      transform scale(1, 1)
      transition max-height .2s ease, opacity .2s ease