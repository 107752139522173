.b-live
  padding: 10px 16px 10px  16px
  &__title
    margin-bottom: 16px
  &__button 
    width 157px
    display block
    padding: 11px 20px
    font: 700 16px/18px "Montserrat", sans-serif
    margin-bottom 24px
  &__slider
    display: none    

.b-live-cards
  &__item
    display: flex
    box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.1)
    border-radius: 12px
    background-color: cWhiteFon
    margin-bottom 20px
    height: 204px
  &__img-container
    position relative 
    display: flex
    width: 47.56%
    overflow: hidden
  &__img
    object-fit: cover
    width: 100%
  &__info-container
    width: 52.44%
    padding: 20px 16px
    display:  flex
    flex-direction: column
    justify-content: space-between
    box-sizing: border-box
  &__title
    font: 700 16px/22.4px "Roboto", sans-serif
    display: -webkit-box
    -webkit-line-clamp: 6
    -webkit-box-orient: vertical
    overflow: hidden
    
  &__text
    display: none
  &__date
    font: 400 14px/19.6px "Roboto", sans-serif
    color cTextGray

@media screen and (min-width: tWidth)
  .b-live
    padding:  36px 24px 40px 24px
    &__slider
      display: block   
    &__cards
      display:  none
    &__title
      -webkit-line-clamp: 8
    &__title,&__button
      display inline-block
      margin-bottom: 30px
    &__button
      margin-left: 18px
  .b-live-slider
    &__container
      position relative
      padding-bottom: 22px
  .b-live-cards
    &__img-container
      width: 46.4%
      display: flex
    &__item
      height: 272px!important
    &__info-container
      padding 26px 12px 19px 22px

@media screen and (min-width: dWidth)
  .b-live
    &__slider
      display: none   
    &__cards
      display:  block
    &__button
      padding: 11px 20px
      font: 700 16px/18px "Montserrat", sans-serif
    &__title,&__button
      margin-bottom: 36px
    &__cards
      display: flex
    
@media screen and (min-width: wWidth)
  .b-live
    padding:  18px 20px 20px 20px
