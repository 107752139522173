.b-header-icons
  &__search-container
    margin-right 38px
    transition: fill .3s ease
    &--active
      .b-header-icons__search
          transition: fill .2s ease
          fill: cGreenActive
  &__search
    transition: fill .3s ease
    width 20px
    height 20px
    &--active
      fill cGreenActive
      transition: fill .2s ease
  &__button
    display none!important
    color cMainBlack!important
  &__open-close
    z-index: 5;
    position relative
    width: 20px;
    height 14px
    &:before, &:after, div
      background: cMainBlack
      content: ""
      display: block
      height: 2px
      border-radius: 3px
      margin: 4.5px 0
      transition: 0.3s
    div
      width: 83%;
      margin-left: auto;
    &._open
      &:before
        transform: translateY(7px) rotate(135deg);
      &:after
        transform: translateY(-6px) rotate(-135deg);
      div
        transform: scale(0)
  
@media screen and (min-width: tWidth)
  .b-header-icons
    &__search-container
      margin-right 26px
    &__button
      display block!important
      margin-right 25px
    &__open-close
      margin-bottom: 6px

@media screen and (min-width: dWidth)

  .b-header-icons
    &__open-close
      display none
    &__search-container
      &:hover
        .b-header-icons__search
          fill cGreenHover
          transition: fill .2s ease

@media screen and (min-width: wWidth)
  .b-header-icons
    &__button
      margin-right: 0