.b-anons
  padding: 30px 16px
  &__title
    margin-bottom: 16px
  &__button
    display block
    margin-bottom: 24px
    width: 157px
    padding: 11px 20px
    font: 700 16px/18px "Montserrat", sans-serif
  &__slider
    display: none
.b-anons-cards
  &__item
    display: block
    box-sizing: border-box
    padding:  20px 16px
    background-color: cWhiteFon
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
    border-radius: 12px
    margin-bottom: 20px

.b-anons-cards-item
  &__tag
    padding: 7px 16px
    border-radius: 6px
    border: 1px solid cBlueDefault
    font: 700 14px/16px "Roboto", sans-serif

    color cBlueDefault
  &__date
    font: 400 14px/19.6px "Roboto", sans-serif
    color: cTextGray
  &__top-block
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px
  &__title
    font: 700 18px/24px "Roboto", sans-serif
    color cMainBlack
    margin-bottom: 12px
  &__text
    font: 400 16px/22.4px "Roboto", sans-serif
    color cMainBlack

@media screen and (min-width: tWidth)
  .b-anons
    padding: 24px 24px
    &__slider
      display: block
    &__cards 
      display: none
    &__title,&__button
      display inline-block
    &__button
      margin-left: 18px
      margin-bottom: 31px
  .b-anons-cards
    &__item
      padding 24px 23px
  .b-anons-cards-item
    &__top-block
      margin-bottom: 20px
  .b-anons-slider
    &__items-container
      display: flex
      position: relative


@media screen and (min-width: dWidth)
  .b-anons
    &__slider
      display: none
    &__cards 
      display: flex
    &__button
      padding: 11px 20px
      font: 700 16px/18px "Montserrat", sans-serif
    &__button,&__title
      margin-bottom: 40px
  .b-anons-cards
      &__item
        padding: 24px 29px 52px 27px
        margin-right: 28px
        width: calc(33.333% - 18.666px)
        cursor pointer
        transition: box-shadow .3s ease
        &:hover
          color cMainBlack
          transition: box-shadow .2s ease
          box-shadow: 0px 2px 24px rgba(51, 51, 51, .12)
        &:last-child
          margin-right 0

@media screen and (min-width: wWidth)
  .b-anons
    padding: 24px 20px
