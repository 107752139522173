.button
  padding 10px 13px
  font: 500 16px/20px "Montserrat", sans-serif
  background cButBlue
  border-radius 6px
  display: inline-block
  transition: background .3s ease
  &:hover
    background cButBlueHover
    transition: background .2s ease
.button-main
  padding: 20px 40px
  text-align: center
  box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
  box-sizing: border-box
  transition: color .3s ease, background-color .3s ease
  border-radius: 6px
  box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
  font: 700 16px/18px "Montserrat", sans-serif
  cursor pointer
  &--green
    color cTextWhite!important
    background-color: btGreen
    transition: color .3s ease, background-color .3s ease
    &:hover
      background-color: btGreenHover
      transition: background-color .2s ease
    &._disabled
      background-color: btGreenDisabled
      transition: background-color .2s ease
  &--blue
    color cTextWhite!important
    background-color: cBlueDefault
    transition: color .3s ease, background-color .3s ease
    &:hover
      background-color: cBlueHover
      transition: background-color .2s ease
    &._active
      background-color: cBlueActive
      transition: background-color .2s ease
  &--transparent
    box-shadow none
    color cTextDarkGreen!important
    background-color: transparent
    transition: color .3s ease, background-color .3s ease
    &:hover
      color: btGreenHover
      transition: color .2s ease

@media screen and (min-width: dWidth)
  .button-main
    &--green
      &:hover
        background-color: btGreenHover!important
        transition: background-color .2s ease
    &--blue
      &:hover
        background-color: cBlueHover!important
        transition: background-color .2s ease
    &--transparent
      &:hover
        color: btGreenHover!important
        transition: color .2s ease
@media screen and (min-width: wWidth)
  .button
    padding 8px 16px

.arrow
  &__item-img
    width: 12px
    height: 12px
    background: url('../img/icons/arrow.svg') no-repeat
    background-size contain
    &--next
      transform: rotate(180deg)
  &__item
    cursor pointer
    width: 48px
    height: 48px
    display: flex
    align-items: center
    justify-content: center
    border-radius:  50%
    background-color: cWhiteFon
    transition: opacity .3s ease, box-shadow .3s ease
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08);
    &--gray
      background-color: cBorder
    &:hover
      transition: box-shadow .2s ease
      box-shadow:0px 2px 24px rgba(51, 51, 51, 0.12)
    &--active
      transition: box-shadow .2s ease
      box-shadow:0px 2px 24px rgba(51, 51, 51, 0.12)
    &--disabled  
        opacity .5
    &--prev
      margin-right: 20px
      &.swiper-button-disabled
          opacity .5
          transition: opacity .2s ease
          pointer-events:  none
    &--next
      &.swiper-button-disabled
          opacity .5
          pointer-events:  none
          transition: opacity .2s ease