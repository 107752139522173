#content-wrapper
    margin: 110px 0 50px!important
.html-width
    min-width: auto!important
.content
    margin-top:  72px
    &__wrapper
        overflow hidden
    &__color
        &--blue
            background-color cContentBlue
        &--blue-shadow
            background-color cContentBlue
            box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08);
        &--gray
            background-color cContentGray
        &--white
            background-color cContentWhite

@media screen and (min-width: tWidth)
    .content
        margin-top:  80px
@media screen and (min-width: wWidth)
    .content
        margin-top: 80px
        &__wrapper
            max-width: 1500px;
            margin: 0 auto;
