.b-command
  padding 60px 86px 50px 86px
.count-container
  &__item
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    margin-bottom: 19px
  &__number
    font: 700 48px/48px "Montserrat", sans-serif
    margin-bottom: 12px
    color cTextDarkGray
  &__text
    margin-bottom: 12px
    font: 400 16px/22.4px "Roboto", sans-serif

@media screen and (min-width: tWidth)
  .b-command
    padding 60px 24px 60px 24px
    &__count-container
      display: flex
  .count-container
    &__item
      width: calc(33.3% - 16px)
      margin-right: 9px
      align-items: center
      margin-bottom 0

@media screen and (min-width: dWidth)
  .b-command
    display: flex
    justify-content: space-between
    align-items: center
    &__count-container
      width 100%


@media screen and (min-width: wWidth)
  .b-command
    padding: 66px 20px 48px 20px
    display: flex
    align-items: center
  .count-container
    &__item
      margin-right: 25px