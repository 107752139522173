
.anons-pagination, .live-pagination, .news-pagination
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;

.swiper-pagination-bullet, .b-anons-pagination-bullet
    width: 12px!important
    height: 12px!important
    margin-right: 10px!important
    background: btGreenDisabled!important
.swiper-pagination-bullet-active
    width: 12px!important
    height: 12px!important
    margin-right: 10px!important
    background: cGreenHover!important
.swiper-pagination-bullets
    bottom: -18px !important
    left: 4px!important