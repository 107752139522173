html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
body {
  line-height: 1;
  font-family: 'Roboto';
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: none;
  -ms-interpolation-mode: bicubic;
}
textarea {
  overflow: auto;
  resize: vertical;
}
button {
  width: auto;
  overflow: visible;
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
* {
  outline: none;
  box-sizing: border-box;
}
input,
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
input::-ms-clear {
  display: none;
}
#mfPreviewBar {
  display: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  text-decoration: none;
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.woff") format('woff'), url("../fonts/Roboto-Regular.woff2") format('woff2');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Lato-Medium.woff") format('woff'), url("../fonts/Lato-Medium.woff2") format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.woff") format('woff'), url("../fonts/Roboto-Bold.woff2") format('woff2');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Lato-Black.ttf") format('ttf'), url("../fonts/Lato-Black.woff") format('woff'), url("../fonts/Lato-Black.woff2") format('woff2');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular.woff") format('woff'), url("../fonts/Montserrat-Regular.woff2") format('woff2');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Bold.woff") format('woff'), url("../fonts/Montserrat-Bold.woff2") format('woff2');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Medium.woff") format('woff'), url("../fonts/Montserrat-Medium.woff2") format('woff2');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Verdana';
  src: url("../fonts/Verdana.woff") format('woff'), url("../fonts/Verdana.woff2") format('woff2');
  font-style: normal;
  font-weight: normal;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.clearfix:after {
  width: 100%;
  clear: both;
}
html,
body {
  height: 100%;
  word-break: break-word;
}
html {
  background: #fff;
  --height-block: 400px;
}
body {
  min-width: 320px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.page {
  flex-grow: 1;
}
.main-html {
  min-width: 320px;
}
@import 'custom/swiper-bundle.min.css';
.button {
  padding: 10px 13px;
  font: 500 16px/20px "Montserrat", sans-serif;
  background: #e1f6fb;
  border-radius: 6px;
  display: inline-block;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.button:hover {
  background: #c3eef9;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
.button-main {
  padding: 20px 40px;
  text-align: center;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  box-sizing: border-box;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  -moz-transition: color 0.3s ease, background-color 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 6px;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  font: 700 16px/18px "Montserrat", sans-serif;
  cursor: pointer;
}
.button-main--green {
  color: #fff !important;
  background-color: #56c25a;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  -moz-transition: color 0.3s ease, background-color 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.button-main--green:hover {
  background-color: #0c9335;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.button-main--green._disabled {
  background-color: #96ef9f;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.button-main--blue {
  color: #fff !important;
  background-color: #006bc6;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  -moz-transition: color 0.3s ease, background-color 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.button-main--blue:hover {
  background-color: #004b9b;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.button-main--blue._active {
  background-color: #087de1;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.button-main--transparent {
  box-shadow: none;
  color: #56c25a !important;
  background-color: transparent;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  -moz-transition: color 0.3s ease, background-color 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.button-main--transparent:hover {
  color: #0c9335;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
@media screen and (min-width: 1280px) {
  .button-main--green:hover {
    background-color: #0c9335 !important;
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
  }
  .button-main--blue:hover {
    background-color: #004b9b !important;
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
  }
  .button-main--transparent:hover {
    color: #0c9335 !important;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
}
@media screen and (min-width: 1920px) {
  .button {
    padding: 8px 16px;
  }
}
.arrow__item-img {
  width: 12px;
  height: 12px;
  background: url("../img/icons/arrow.svg") no-repeat;
  background-size: contain;
}
.arrow__item-img--next {
  transform: rotate(180deg);
}
.arrow__item {
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: opacity 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: opacity 0.3s ease, box-shadow 0.3s ease;
  -o-transition: opacity 0.3s ease, box-shadow 0.3s ease;
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
}
.arrow__item--gray {
  background-color: #f1f1f1;
}
.arrow__item:hover {
  -webkit-transition: box-shadow 0.2s ease;
  -moz-transition: box-shadow 0.2s ease;
  -o-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
}
.arrow__item--active {
  -webkit-transition: box-shadow 0.2s ease;
  -moz-transition: box-shadow 0.2s ease;
  -o-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
}
.arrow__item--disabled {
  opacity: 0.5;
}
.arrow__item--prev {
  margin-right: 20px;
}
.arrow__item--prev.swiper-button-disabled {
  opacity: 0.5;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  pointer-events: none;
}
.arrow__item--next.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.b-footer {
  min-width: 320px;
  margin-top: 9px;
  background-color: #333;
  box-sizing: border-box;
  font: 400 16px/20px "Montserrat", sans-serif;
}
.b-footer__menu {
  display: flex;
  flex-wrap: wrap;
}
.b-footer__menu-column {
  width: 100%;
}
.b-footer__wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 16px 40px 16px;
}
.b-footer__menu-link {
  padding: 10px 0;
  width: 100%;
  display: block;
  color: #fff !important;
}
.b-footer__contacts {
  margin-top: 40px;
  font: 400 16px/20px "Roboto", sans-serif;
}
.b-footer__socials {
  display: flex;
  margin-top: 40px;
}
.b-footer__bottom-block {
  margin-top: 40px;
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
}
.b-footer-contacts__phone,
.b-footer-contacts__mail {
  color: #fff !important;
  display: inline-block;
  margin-top: 20px;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.b-footer-contacts__phone:hover,
.b-footer-contacts__mail:hover {
  opacity: 0.7;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.b-footer-contacts__contact-container {
  width: 100%;
}
.b-footer-socials__item {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-footer-socials__item-img {
  width: 24px;
  height: 24px;
}
.b-footer-socials__item-img--tg {
  background: url("../img/icons/tg-footer.svg") no-repeat;
  background-size: contain;
}
.b-footer-socials__item-img--rut {
  background: url("../img/icons/rutube-footer.svg") no-repeat;
  background-size: contain;
}
.b-footer-socials__item-img--vk {
  background: url("../img/icons/vk-footer.svg") no-repeat;
  background-size: contain;
}
.b-footer-socials__item-img--yout {
  background: url("../img/icons/yout-footer.svg") no-repeat;
  background-size: contain;
}
.b-footer-socials__item-img--dzen {
  background: url("../img/icons/dzen-footer.svg") no-repeat;
  background-size: contain;
}
.b-footer-bottom-block__text {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.b-footer-bottom-block__text-link {
  display: inline;
  color: #a5a5a5 !important;
}
@media screen and (min-width: 768px) {
  .b-footer {
    display: flex;
    flex-wrap: wrap;
  }
  .b-footer__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 31px 22px 53px 22px;
  }
  .b-footer__menu {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
  }
  .b-footer__menu-column {
    width: 50%;
  }
  .b-footer__menu-link {
    width: fit-content;
  }
  .b-footer__contacts {
    width: calc(40% - 100px);
    margin-top: -12px;
    margin-left: 100px;
  }
  .b-footer__socials {
    width: 100%;
    margin-top: 34px;
  }
  .b-footer__bottom-block {
    margin-top: 44px;
    display: flex;
  }
  .b-footer-socials__item {
    margin-right: 16px;
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }
  .b-footer-socials__item:hover {
    opacity: 0.7;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
  }
  .b-footer-bottom-block__text {
    margin-right: 46px;
  }
}
@media screen and (min-width: 1920px) {
  .b-footer__menu {
    width: 42%;
  }
  .b-footer__contacts {
    margin-left: 126px;
    margin-right: 30px;
    width: 22%;
  }
  .b-footer__wrapper {
    padding: 31px 0px 53px 0px;
    display: flex;
    flex-wrap: wrap;
    width: 1460px;
    margin: 0 auto;
  }
  .b-footer__socials {
    width: 25%;
    margin-top: 9px;
  }
  .b-footer__menu-link {
    -webkit-transition: opacity 0.3 ease;
    -moz-transition: opacity 0.3 ease;
    -o-transition: opacity 0.3 ease;
    transition: opacity 0.3 ease;
  }
  .b-footer__menu-link:hover {
    -webkit-transition: opacity 0.2 ease;
    -moz-transition: opacity 0.2 ease;
    -o-transition: opacity 0.2 ease;
    transition: opacity 0.2 ease;
    opacity: 0.7;
  }
  .b-footer__bottom-block {
    width: 100%;
    padding-right: 144px;
    margin-top: 49px;
    justify-content: space-between;
  }
  .b-footer-bottom-block__text {
    flex-direction: row;
  }
  .b-footer-socials__item:first-child {
    margin-left: 18px;
  }
}
#content-wrapper {
  margin: 110px 0 50px !important;
}
.html-width {
  min-width: auto !important;
}
.content {
  margin-top: 72px;
}
.content__wrapper {
  overflow: hidden;
}
.content__color--blue {
  background-color: #edf7fa;
}
.content__color--blue-shadow {
  background-color: #edf7fa;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
}
.content__color--gray {
  background-color: #f1f1f1;
}
.content__color--white {
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .content {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1920px) {
  .content {
    margin-top: 80px;
  }
  .content__wrapper {
    max-width: 1500px;
    margin: 0 auto;
  }
}
.anons-pagination,
.live-pagination,
.news-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  -o-transition: 0.3s opacity;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination-bullet,
.b-anons-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  margin-right: 10px !important;
  background: #96ef9f !important;
}
.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  margin-right: 10px !important;
  background: #56c25a !important;
}
.swiper-pagination-bullets {
  bottom: -18px !important;
  left: 4px !important;
}
.text h3 {
  color: #333;
  font: 700 24px/36px "Montserrat", sans-serif;
}
.text h4 {
  color: #333;
  font: 700 20px/28px "Montserrat", sans-serif;
}
.text p {
  color: #333;
  font: 400 18px/24px "Roboto", sans-serif;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .text h3 {
    font: 700 30px/36px "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .text h3 {
    font: 700 30px/36px "Montserrat", sans-serif;
  }
}
.b-card__item {
  display: flex;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
  height: 204px;
}
.b-card__img-container {
  position: relative;
  width: 47.56%;
  display: flex;
  overflow: hidden;
}
.b-card__img {
  object-fit: cover;
  width: 100%;
}
.b-card__info-container {
  width: 52.44%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.b-card__title {
  color: #333;
  font: 700 16px/22.4px "Roboto", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.b-card__text {
  color: #333;
  display: none;
}
.b-card__date {
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
}
@media screen and (min-width: 768px) {
  .b-card__img-container {
    width: 46.4%;
    border-radius: 12px 0px 0px 12px;
    display: flex;
  }
  .b-card__item {
    height: 272px !important;
  }
  .b-card__info-container {
    padding: 26px 16px 20px 21px;
  }
}
@media screen and (min-width: 1280px) {
  .b-card__title {
    font: 700 18px/24px "Roboto", sans-serif;
    -webkit-line-clamp: 5;
  }
  .b-card__text {
    display: inline;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: 400 16px/22.4px "Roboto", sans-serif;
  }
  .b-card__item {
    margin-right: 28px;
    width: calc(33.333% - 18.666px);
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  }
  .b-card__item:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
  .b-card__item:last-child {
    margin-right: 0;
  }
  .b-card__img-container {
    width: 49.4%;
  }
}
@media screen and (min-width: 1920px) {
  .b-card__title {
    font: 700 18px/24px "Roboto", sans-serif;
    -webkit-line-clamp: 5;
  }
  .b-card__text {
    display: inline;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: 400 16px/22.4px "Roboto", sans-serif;
  }
  .b-card__img-container {
    width: 49.4%;
  }
  .b-card__item {
    height: 300px !important;
  }
}
.b-header {
  width: 100%;
  height: 72px;
  min-width: 320px;
  display: flex;
  padding: 16px 18px 16px 16px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px rgba(0,0,0,0.12);
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  z-index: 100;
}
.b-header._open-menu {
  box-shadow: none;
}
.b-header._scrolling {
  box-shadow: 0px 2px 12px rgba(0,0,0,0.12) !important;
}
.b-header__wrapper {
  align-items: center;
  display: flex;
  width: 100%;
}
.b-header__logo {
  min-width: 43px;
  height: 40px;
  margin-right: 13px;
  background: url("../img/logo.png ") no-repeat;
}
.b-header__text {
  color: #333 !important;
  font: 700 16px/18px "Montserrat", sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
}
.b-header__desktop-menu {
  display: none;
}
.b-header__icons-container {
  display: flex;
  margin-left: auto;
  margin-bottom: 4px;
}
@media screen and (min-width: 768px) {
  .b-header {
    height: 80px;
    padding: 24px 27px 23px 23px;
  }
  .b-header__text {
    font: 700 24px/33.6px "Montserrat", sans-serif;
  }
  .b-header__icons-container {
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .b-header__desktop-menu {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 34px;
  }
  .b-header__logo {
    margin: 0;
    padding-right: 11.5px;
  }
}
@media screen and (min-width: 1920px) {
  .b-header {
    width: 100%;
    box-sizing: border-box;
  }
  .b-header__wrapper {
    max-width: 1460px;
    margin: 0 auto;
  }
  .b-header__desktop-menu {
    margin-left: 109px;
    margin-right: 37px;
  }
}
.b-header-icons__search-container {
  margin-right: 38px;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.b-header-icons__search-container--active .b-header-icons__search {
  -webkit-transition: fill 0.2s ease;
  -moz-transition: fill 0.2s ease;
  -o-transition: fill 0.2s ease;
  transition: fill 0.2s ease;
  fill: #0c9335;
}
.b-header-icons__search {
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  width: 20px;
  height: 20px;
}
.b-header-icons__search--active {
  fill: #0c9335;
  -webkit-transition: fill 0.2s ease;
  -moz-transition: fill 0.2s ease;
  -o-transition: fill 0.2s ease;
  transition: fill 0.2s ease;
}
.b-header-icons__button {
  display: none !important;
  color: #333 !important;
}
.b-header-icons__open-close {
  z-index: 5;
  position: relative;
  width: 20px;
  height: 14px;
}
.b-header-icons__open-close:before,
.b-header-icons__open-close:after,
.b-header-icons__open-close div {
  background: #333;
  content: "";
  display: block;
  height: 2px;
  border-radius: 3px;
  margin: 4.5px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.b-header-icons__open-close div {
  width: 83%;
  margin-left: auto;
}
.b-header-icons__open-close._open:before {
  transform: translateY(7px) rotate(135deg);
}
.b-header-icons__open-close._open:after {
  transform: translateY(-6px) rotate(-135deg);
}
.b-header-icons__open-close._open div {
  transform: scale(0);
}
@media screen and (min-width: 768px) {
  .b-header-icons__search-container {
    margin-right: 26px;
  }
  .b-header-icons__button {
    display: block !important;
    margin-right: 25px;
  }
  .b-header-icons__open-close {
    margin-bottom: 6px;
  }
}
@media screen and (min-width: 1280px) {
  .b-header-icons__open-close {
    display: none;
  }
  .b-header-icons__search-container:hover .b-header-icons__search {
    fill: #56c25a;
    -webkit-transition: fill 0.2s ease;
    -moz-transition: fill 0.2s ease;
    -o-transition: fill 0.2s ease;
    transition: fill 0.2s ease;
  }
}
@media screen and (min-width: 1920px) {
  .b-header-icons__button {
    margin-right: 0;
  }
}
@media screen and (min-width: 1280px) {
  .b-header-desktop-menu__submenu-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
    background-color: #fff;
    margin-top: 40px;
    padding: 8px 20px;
    border-radius: 7px;
  }
  .b-header-desktop-menu__title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .b-header-desktop-menu__submenu {
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    min-width: 100%;
    -webkit-transition: opacity 0.15s ease, visibility 0.15s ease;
    -moz-transition: opacity 0.15s ease, visibility 0.15s ease;
    -o-transition: opacity 0.15s ease, visibility 0.15s ease;
    transition: opacity 0.15s ease, visibility 0.15s ease;
    top: 22px;
  }
  .b-header-desktop-menu__item {
    position: relative;
    color: #333;
    display: flex;
    align-items: flex-start;
    margin-right: 5px;
    flex-direction: column;
  }
  .b-header-desktop-menu__item:hover .b-header-desktop-menu__link {
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    color: #56c25a !important;
  }
  .b-header-desktop-menu__item:hover .b-header-desktop-menu__arrow {
    -webkit-transition: fill 0.2s ease, transform 0.2s ease;
    -moz-transition: fill 0.2s ease, transform 0.2s ease;
    -o-transition: fill 0.2s ease, transform 0.2s ease;
    transition: fill 0.2s ease, transform 0.2s ease;
    transform: rotate(180deg);
    transform-origin: center center;
    fill: #56c25a;
  }
  .b-header-desktop-menu__item:hover .b-header-desktop-menu__submenu {
    -webkit-transition: opacity 0.2s ease, visibility 0.2s ease;
    -moz-transition: opacity 0.2s ease, visibility 0.2s ease;
    -o-transition: opacity 0.2s ease, visibility 0.2s ease;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    opacity: 1;
    visibility: visible;
  }
  .b-header-desktop-menu__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    font: 500 15px/20px "Montserrat", sans-serif;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    color: #333 !important;
  }
  .b-header-desktop-menu__link:focus:active {
    color: #0c9335 !important;
  }
  .b-header-desktop-menu__link:focus:active .b-header-desktop-menu__arrow {
    transform-origin: center center;
    -webkit-transition: opacity 0.15s ease, transform 0.15s ease;
    -moz-transition: opacity 0.15s ease, transform 0.15s ease;
    -o-transition: opacity 0.15s ease, transform 0.15s ease;
    transition: opacity 0.15s ease, transform 0.15s ease;
    transform: rotate(180deg);
    transform-origin: center center;
    fill: #0c9335;
  }
  .b-header-desktop-menu__link:focus {
    color: #56c25a !important;
  }
  .b-header-desktop-menu__link:focus .b-header-desktop-menu__arrow {
    transform-origin: center center;
    -webkit-transition: opacity 0.15s ease, transform 0.15s ease;
    -moz-transition: opacity 0.15s ease, transform 0.15s ease;
    -o-transition: opacity 0.15s ease, transform 0.15s ease;
    transition: opacity 0.15s ease, transform 0.15s ease;
    transform: rotate(180deg);
    transform-origin: center center;
    fill: #56c25a;
  }
  .b-header-desktop-menu__arrow-container {
    margin-left: 10px;
  }
  .b-header-desktop-submenu__item {
    padding: 12px 0px;
    border-bottom: 1px solid #f1f1f1;
    white-space: nowrap;
    background-color: #fff;
    color: #333 !important;
    -webkit-transition: color 0.15s ease;
    -moz-transition: color 0.15s ease;
    -o-transition: color 0.15s ease;
    transition: color 0.15s ease;
  }
  .b-header-desktop-submenu__item:hover {
    color: #56c25a !important;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  .b-header-desktop-submenu__item--active {
    color: #0c9335;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  .b-header-desktop-submenu__item:last-child {
    border-bottom: none;
  }
  .b-header-desktop-submenu__item {
    padding: 12px 21px 12px 0px;
    border-bottom: 1px solid #f1f1f1;
    white-space: nowrap;
    background-color: #fff;
  }
  .b-header-desktop-submenu__item:last-child {
    border-bottom: none;
  }
}
@media screen and (min-width: 1920px) {
  .b-header-desktop-menu__item {
    margin-right: 19px;
  }
  .b-header-desktop-menu__item._special {
    margin-right: 14px;
  }
  .b-header-desktop-menu__link {
    margin-right: 5px;
    font: 500 16px/20px "Montserrat", sans-serif;
  }
}
.b-mobile-menu {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 10;
  font: 500 18px/24px "Montserrat", sans-serif;
  top: -90%;
  padding: 0 16px;
  overflow: auto;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  padding-top: 12px;
  height: calc(100% - 72px);
  -webkit-transition: max-height 0.3s ease, top 0.3s ease;
  -moz-transition: max-height 0.3s ease, top 0.3s ease;
  -o-transition: max-height 0.3s ease, top 0.3s ease;
  transition: max-height 0.3s ease, top 0.3s ease;
  background: #fff;
  margin-top: 72px;
}
.b-mobile-menu._open {
  max-height: 900px;
  z-index: 50;
  overflow: auto;
  top: 0px !important;
}
.b-mobile-menu__button {
  margin-top: 28px;
  padding: 10px 51px !important;
  color: #333 !important;
}
.b-mobile-menu__item._open .b-mobile-menu-item__arrow {
  transform: rotate(180deg);
  transform-origin: center center;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  fill: #0c9335;
}
.b-mobile-menu__footer {
  margin-top: 40px;
  width: calc(100% + 32px);
  margin-left: -16px;
  box-sizing: border-box;
  background-color: #333;
  padding: 30px 16px 40px 16px;
  font: 400 16px/20px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .b-mobile-menu {
    display: flex;
    height: calc(100% - 80px);
    margin-top: 80px;
    padding: 0;
  }
  .b-mobile-menu__button {
    display: none !important;
  }
  .b-mobile-menu__wrapper {
    overflow: hidden;
    width: 100%;
    display: flex;
  }
  .b-mobile-menu__scrolling-container {
    overflow: auto;
    height: 100%;
    width: 51.5%;
    padding: 12px 24px 20px 24px;
    box-sizing: border-box;
  }
  .b-mobile-menu__footer {
    width: 48.5%;
    margin-left: 0;
    margin-top: 0;
    padding: 5px 24px 40px 24px;
  }
  .b-mobile-menu__footer .b-footer__contacts {
    width: 100%;
    margin: 0;
  }
  .b-mobile-menu__footer .b-footer__bottom-block {
    margin-top: 42px;
    display: block;
  }
  .b-mobile-menu__footer .b-footer__socials {
    margin-top: 39px;
  }
  .b-mobile-menu__footer .b-footer-socials__item {
    margin-right: 24px;
  }
}
.b-mobile-menu-item__item {
  border-bottom: 1px solid #f1f1f1;
}
.b-mobile-menu-item__link {
  display: inline-block;
  padding: 12px 6px 11px 0;
  position: relative;
}
.b-mobile-menu-item__link._open {
  color: #0c9335;
}
.b-mobile-menu-item__link._open:before {
  background: url("../img/icons/menu-arrow-active.svg") no-repeat;
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transform: rotate(180deg);
}
.b-mobile-menu-item__arrow-container {
  padding-left: 6px;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  display: inline;
}
.b-mobile-menu-item__arrow {
  transform-origin: center center;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.b-mobile-menu-item__submenu {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: max-height 0.3s ease, opacity 0.3s ease;
  -moz-transition: max-height 0.3s ease, opacity 0.3s ease;
  -o-transition: max-height 0.3s ease, opacity 0.3s ease;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
.b-mobile-menu-item__submenu._open {
  max-height: 0;
  visibility: visible;
  max-height: 400px;
  opacity: 1;
  transform: scale(1, 1);
  -webkit-transition: max-height 0.2s ease, opacity 0.2s ease;
  -moz-transition: max-height 0.2s ease, opacity 0.2s ease;
  -o-transition: max-height 0.2s ease, opacity 0.2s ease;
  transition: max-height 0.2s ease, opacity 0.2s ease;
}
.b-mobile-menu-submenu__item:first-child .b-mobile-menu-submenu__link {
  padding-top: 12px;
}
.b-mobile-menu-submenu__item:last-child .b-mobile-menu-submenu__link {
  padding-bottom: 21px;
}
.b-mobile-menu-submenu__link {
  display: block;
  color: #333 !important;
  padding: 10px 40px 10px 40px;
  font: 400 16px/22.4px "Roboto", sans-serif;
}
@media screen and (min-width: 768px) {
  .b-mobile-menu-submenu__item:first-child .b-mobile-menu-submenu__link {
    padding-top: 14px;
  }
  .b-mobile-menu-submenu__item:last-child .b-mobile-menu-submenu__link {
    padding-bottom: 24px;
  }
}
.b-main-banner {
  padding: 32px 16px 80px 16px;
}
.b-main-banner__socials-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.b-main-banner__slider {
  margin-bottom: 20px;
  width: 100%;
}
.b-main-banner__slider-button {
  display: flex;
}
.b-main-banner__muilty {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.b-main-info__title {
  font: 700 36px/44px "Montserrat", sans-serif;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.b-main-info__text {
  font: 400 18px/24px "Roboto", sans-serif;
  margin-bottom: 44px;
}
.b-main-info__button {
  margin-bottom: 12px;
  display: block;
}
.b-main-info__button-container {
  margin-bottom: 44px;
}
.banner-slider__items-container {
  display: flex;
}
.banner-slider__item {
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 20px rgba(51,51,51,0.1);
  border-radius: 12px;
}
.banner-slider-item__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner-slider-item__img-container {
  height: 0;
  padding-top: 85.37%;
  overflow: hidden;
  position: relative;
}
.banner-slider-item__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-slider-item__data-container {
  color: #333;
  padding: 16px 16px 56px 16px;
  position: relative;
}
.banner-slider-item__title {
  font: 700 18px/24px "Roboto", sans-serif;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.banner-slider-item__text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font: 400 16px/22.4px "Roboto", sans-serif;
}
.banner-slider-item__date {
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
  margin-top: 16px;
  position: absolute;
  bottom: 20px;
  left: 16px;
}
.banner-socials-container__item-img {
  fill: #006bc6;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.banner-slider-button__item-img {
  width: 12px;
  height: 12px;
  background: url("../img/icons/arrow.svg") no-repeat;
  background-size: contain;
}
.banner-slider-button__item-img--next {
  transform: rotate(180deg);
}
.banner-slider-button__item {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 2px 12px rgba(51,51,51,0.08));
}
.banner-slider-button__item--active {
  filter: drop-shadow(0px 2px 24px rgba(51,51,51,0.12));
}
.banner-slider-button__item--disabled {
  opacity: 0.5;
}
.banner-slider-button__item--prev {
  margin-right: 20px;
}
@media screen and (min-width: 768px) {
  .b-main-banner {
    padding: 44px 24px 80px 24px;
  }
  .b-main-banner__slider {
    margin-bottom: 31px;
  }
  .b-main-banner__socials-container {
    margin-left: 12px;
  }
  .b-main-info__button-container {
    display: flex;
  }
  .b-main-info__button {
    margin-left: 7px;
  }
  .b-main-info__button--special {
    margin-left: 0px;
  }
  .b-main-info__text {
    font: 400 20px/32px "Roboto", sans-serif;
  }
  .b-main-info__button-container {
    margin-bottom: 70px;
  }
  .banner-slider__item {
    display: flex;
    height: 300px !important;
  }
  .banner-slider-item__img-container {
    width: 48.3%;
    padding: 0;
    height: 300px;
  }
  .banner-slider-item__img {
    position: relative;
    object-fit: cover;
  }
  .banner-slider-item__data-container {
    width: 51.7%;
    box-sizing: border-box;
    padding: 22px 25px 20px 25px;
    height: 300px;
  }
  .banner-slider-item__date {
    position: absolute;
    bottom: 20px;
  }
  .banner-socials-container__item {
    margin-right: 8px;
  }
}
@media screen and (min-width: 1280px) {
  .b-main-banner {
    padding: 85px 36px 60px 36px;
    display: flex;
  }
  .b-main-banner__slider {
    overflow: hidden;
    margin-bottom: 7px;
    padding: 20px 20px 24px 15px;
    margin-right: -20px;
  }
  .b-main-banner__info {
    margin-right: 40px;
    width: calc(50% - 40px);
  }
  .b-main-banner__muilty {
    position: relative;
    width: 50%;
  }
  .b-main-banner__slider-button {
    width: 100%;
    justify-content: flex-end;
  }
  .b-main-banner__socials-container {
    position: absolute;
    top: -45px;
    right: 0;
  }
  .b-main-info__button {
    font-size: 14px;
  }
  .banner-socials-container__item {
    cursor: pointer;
  }
  .banner-socials-container__item:hover .banner-socials-container__item-img {
    -webkit-transition: fill 0.2s ease;
    -moz-transition: fill 0.2s ease;
    -o-transition: fill 0.2s ease;
    transition: fill 0.2s ease;
    fill: #004b9b;
  }
}
@media screen and (min-width: 1920px) {
  .b-main-banner {
    padding: 103px 12px 60px 12px;
  }
  .b-main-banner__info {
    margin-right: 216px;
    width: 652px;
  }
  .b-main-banner__muilty {
    width: 592px;
  }
  .b-main-banner__socials-container {
    top: -76px;
  }
  .banner-slider-item {
    cursor: pointer;
  }
  .banner-slider-item:hover {
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
    color: #333;
  }
  .banner-slider-item__img-container {
    width: 73.74%;
  }
  .banner-slider-item__date {
    left: 25px;
  }
  .b-main-info__title {
    font: 700 72px/72px "Montserrat", sans-serif;
    margin-bottom: 40px;
  }
  .b-main-info__text {
    margin-bottom: 53px;
  }
  .b-main-info__button {
    font-size: 18px;
  }
  .banner-socials-container__item {
    margin-right: 12px;
  }
  .banner-socials-container__item:last-child {
    margin-right: 0;
  }
}
.b-command {
  padding: 60px 86px 50px 86px;
}
.count-container__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 19px;
}
.count-container__number {
  font: 700 48px/48px "Montserrat", sans-serif;
  margin-bottom: 12px;
  color: #585858;
}
.count-container__text {
  margin-bottom: 12px;
  font: 400 16px/22.4px "Roboto", sans-serif;
}
@media screen and (min-width: 768px) {
  .b-command {
    padding: 60px 24px 60px 24px;
  }
  .b-command__count-container {
    display: flex;
  }
  .count-container__item {
    width: calc(33.3% - 16px);
    margin-right: 9px;
    align-items: center;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) {
  .b-command {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .b-command__count-container {
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .b-command {
    padding: 66px 20px 48px 20px;
    display: flex;
    align-items: center;
  }
  .count-container__item {
    margin-right: 25px;
  }
}
.news-list table img {
  max-width: 192px;
  max-height: 192px;
  object-fit: cover;
}
.b-project {
  padding: 80px 16px 30px 16px;
}
.b-project__title {
  margin-bottom: 16px;
  text-align: center;
}
.b-project__text {
  margin-bottom: 32px;
}
.b-project__bottom-block {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.b-project__slider {
  margin-bottom: 43px;
}
.b-project-slider__items-container {
  display: flex;
}
.b-project-slider__item {
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  border-radius: 12px;
  height: auto !important;
}
.b-project-slider-item__img-container {
  height: 0;
  padding-bottom: 70.515%;
  position: relative;
}
.b-project-slider-item__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.b-project-slider-item__data-container {
  color: #333;
  padding: 12px 16px 20px 16px;
}
.b-project-slider-item__title {
  color: #333;
  width: 90%;
  font: 700 18px/24px "Roboto", sans-serif;
}
.b-project-bottom-block__arrow {
  display: flex;
}
.b-project-bottom-block__button {
  padding: 15px 30px;
  font: 700 16px/18px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .b-project {
    padding: 52px 24px 40px 24px;
  }
  .b-project__bottom-block {
    justify-content: center;
  }
  .b-project__slider {
    margin-bottom: 45px;
  }
  .b-project-bottom-block__arrow {
    position: absolute;
    top: 0;
    right: 0;
  }
  .b-project-slider-item__title {
    width: 100%;
  }
  .b-project-slider-item__data-container {
    padding: 15px 16px 20px 19px;
  }
}
@media screen and (min-width: 1280px) {
  .b-project__text {
    padding: 0 350px;
    margin-bottom: 39px;
    text-align: center;
  }
  .b-project__title {
    margin-bottom: 20px;
  }
  .b-project__slider {
    margin-bottom: 38px;
    overflow: hidden;
  }
  .b-project__bottom-block {
    padding: 0 20px;
  }
  .b-project-bottom-block__button {
    padding: 15px 40px;
  }
  .b-project-bottom-block__arrow {
    right: 20px;
  }
  .b-project-slider__items-container {
    padding: 0 20px 24px 20px;
  }
  .b-project-slider__item {
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
  }
  .b-project-slider__item:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
}
@media screen and (min-width: 1920px) {
  .b-project {
    padding: 121px 0 40px 0;
  }
}
.b-community {
  padding: 40px 24px 58px 24px;
}
.b-community__questions-block,
.b-community__lection-block {
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
  display: block;
}
.b-community__title {
  color: #333;
  font: 700 20px/28px "Montserrat", sans-serif;
}
.b-community__title--short {
  width: 70%;
  margin-bottom: 24px;
}
.b-community__button {
  width: 187px;
  padding: 15px 30px;
  display: block;
}
.b-community-questions {
  display: block;
}
.b-community-questions__img-container {
  position: relative;
  height: 0;
  padding-top: 67.38%;
}
.b-community-questions__date {
  padding: 20px 34px 20px 15px;
}
.b-community-questions__img {
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  width: 100%;
  height: 100%;
}
.b-community-lection__img-container {
  position: relative;
  height: 0;
  padding-top: 67%;
}
.b-community-lection__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.b-community-lection__date {
  padding: 20px 34px 20px 15px;
}
@media screen and (min-width: 768px) {
  .b-community {
    padding: 40px 24px 60px 24px;
  }
  .b-community__questions-block,
  .b-community__lection-block {
    display: flex;
    margin-bottom: 23px;
  }
  .b-community__title {
    margin-bottom: 32px;
  }
  .b-community__title--short {
    font: 700 24px/36px "Montserrat", sans-serif;
    width: 90%;
  }
  .b-community__button {
    padding: 20px 40px;
    display: inline-block;
    width: auto;
    font: 700 18px/20px "Montserrat", sans-serif;
  }
  .b-community-questions__img-container {
    width: 64.6%;
    padding-top: 41.7%;
  }
  .b-community-questions__date {
    padding: 60px 69px 20px 24px;
    width: 51.77777%;
  }
  .b-community-lection__img-container {
    width: 64.6%;
    padding-top: 30%;
    margin-bottom: 0px;
  }
  .b-community-lection__date {
    padding: 60px 69px 20px 24px;
    width: 51.77777%;
  }
}
@media screen and (min-width: 1280px) {
  .b-community {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .b-community__questions-block {
    width: 75%;
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
  }
  .b-community__questions-block:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
  .b-community__lection-block {
    flex-direction: column;
    width: calc(25% - 28px);
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  .b-community__lection-block:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
  .b-community__lection-block--wide {
    width: calc(50% - 14px);
  }
  .b-community__lection-block--wide .b-community-lection__img-container {
    height: 260px;
  }
  .b-community__title {
    margin-bottom: 0;
    font: 700 18px/24px "Montserrat", sans-serif;
  }
  .b-community__title--short {
    margin-bottom: 40px;
    font: 700 24px/36px "Montserrat", sans-serif;
  }
  .b-community-questions__date {
    padding: 60px 64px 21px 27px;
  }
  .b-community-questions__img-container {
    padding: 0;
    height: 300px;
    display: flex;
    width: 75.6%;
  }
  .b-community-questions__img {
    object-fit: cover;
    position: relative;
  }
  .b-community-lection__img-container {
    padding: 0;
    height: 214px;
    display: flex;
    width: 100%;
  }
  .b-community-lection__img {
    object-fit: cover;
    position: relative;
  }
  .b-community-lection__date {
    padding: 17px 24px 20px 17px;
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .b-community {
    padding: 82px 20px 96px 20px;
  }
}
.b-news {
  padding: 80px 16px 30px 24px;
}
.b-news__title {
  text-align: center;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .b-news {
    padding: 80px 24px 22px 24px;
  }
  .b-news__title {
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .b-news__title,
  .b-news__text {
    padding: 0 360px;
    text-align: center;
  }
}
@media screen and (min-width: 1920px) {
  .b-news {
    padding: 122px 6px 37px 6px;
  }
}
.b-anons {
  padding: 30px 16px;
}
.b-anons__title {
  margin-bottom: 16px;
}
.b-anons__button {
  display: block;
  margin-bottom: 24px;
  width: 157px;
  padding: 11px 20px;
  font: 700 16px/18px "Montserrat", sans-serif;
}
.b-anons__slider {
  display: none;
}
.b-anons-cards__item {
  display: block;
  box-sizing: border-box;
  padding: 20px 16px;
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  border-radius: 12px;
  margin-bottom: 20px;
}
.b-anons-cards-item__tag {
  padding: 7px 16px;
  border-radius: 6px;
  border: 1px solid #006bc6;
  font: 700 14px/16px "Roboto", sans-serif;
  color: #006bc6;
}
.b-anons-cards-item__date {
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
}
.b-anons-cards-item__top-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.b-anons-cards-item__title {
  font: 700 18px/24px "Roboto", sans-serif;
  color: #333;
  margin-bottom: 12px;
}
.b-anons-cards-item__text {
  font: 400 16px/22.4px "Roboto", sans-serif;
  color: #333;
}
@media screen and (min-width: 768px) {
  .b-anons {
    padding: 24px 24px;
  }
  .b-anons__slider {
    display: block;
  }
  .b-anons__cards {
    display: none;
  }
  .b-anons__title,
  .b-anons__button {
    display: inline-block;
  }
  .b-anons__button {
    margin-left: 18px;
    margin-bottom: 31px;
  }
  .b-anons-cards__item {
    padding: 24px 23px;
  }
  .b-anons-cards-item__top-block {
    margin-bottom: 20px;
  }
  .b-anons-slider__items-container {
    display: flex;
    position: relative;
  }
}
@media screen and (min-width: 1280px) {
  .b-anons__slider {
    display: none;
  }
  .b-anons__cards {
    display: flex;
  }
  .b-anons__button {
    padding: 11px 20px;
    font: 700 16px/18px "Montserrat", sans-serif;
  }
  .b-anons__button,
  .b-anons__title {
    margin-bottom: 40px;
  }
  .b-anons-cards__item {
    padding: 24px 29px 52px 27px;
    margin-right: 28px;
    width: calc(33.333% - 18.666px);
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
  }
  .b-anons-cards__item:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
  .b-anons-cards__item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 1920px) {
  .b-anons {
    padding: 24px 20px;
  }
}
.b-live {
  padding: 10px 16px 10px 16px;
}
.b-live__title {
  margin-bottom: 16px;
}
.b-live__button {
  width: 157px;
  display: block;
  padding: 11px 20px;
  font: 700 16px/18px "Montserrat", sans-serif;
  margin-bottom: 24px;
}
.b-live__slider {
  display: none;
}
.b-live-cards__item {
  display: flex;
  box-shadow: 0px 2px 20px rgba(51,51,51,0.1);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
  height: 204px;
}
.b-live-cards__img-container {
  position: relative;
  display: flex;
  width: 47.56%;
  overflow: hidden;
}
.b-live-cards__img {
  object-fit: cover;
  width: 100%;
}
.b-live-cards__info-container {
  width: 52.44%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.b-live-cards__title {
  font: 700 16px/22.4px "Roboto", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.b-live-cards__text {
  display: none;
}
.b-live-cards__date {
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
}
@media screen and (min-width: 768px) {
  .b-live {
    padding: 36px 24px 40px 24px;
  }
  .b-live__slider {
    display: block;
  }
  .b-live__cards {
    display: none;
  }
  .b-live__title {
    -webkit-line-clamp: 8;
  }
  .b-live__title,
  .b-live__button {
    display: inline-block;
    margin-bottom: 30px;
  }
  .b-live__button {
    margin-left: 18px;
  }
  .b-live-slider__container {
    position: relative;
    padding-bottom: 22px;
  }
  .b-live-cards__img-container {
    width: 46.4%;
    display: flex;
  }
  .b-live-cards__item {
    height: 272px !important;
  }
  .b-live-cards__info-container {
    padding: 26px 12px 19px 22px;
  }
}
@media screen and (min-width: 1280px) {
  .b-live__slider {
    display: none;
  }
  .b-live__cards {
    display: block;
  }
  .b-live__button {
    padding: 11px 20px;
    font: 700 16px/18px "Montserrat", sans-serif;
  }
  .b-live__title,
  .b-live__button {
    margin-bottom: 36px;
  }
  .b-live__cards {
    display: flex;
  }
}
@media screen and (min-width: 1920px) {
  .b-live {
    padding: 18px 20px 20px 20px;
  }
}
.b-blog,
.b-news-info {
  padding: 80px 16px;
}
.b-blog__title,
.b-news-info__title {
  text-align: center;
  margin-bottom: 25px;
}
.b-blog__button,
.b-news-info__button {
  display: block;
  width: 177px;
  padding: 15px 30px;
  margin-top: 34px;
  font: 700 16px/18px "Montserrat", sans-serif;
}
.b-blog-cards__item {
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  border-radius: 12px;
  margin-bottom: 20px;
}
.b-blog-card-item__img-container {
  position: relative;
  height: 0;
  padding-top: 69.515%;
}
.b-blog-card-item__img-container--mini {
  padding-top: 56.5%;
}
.b-blog-card-item__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.b-blog-card-item__info-blog {
  padding: 16px 16px 20px 16px;
}
.b-blog-card-item__title {
  color: #333;
  font: 700 18px/24px "Roboto", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 44px;
  margin-bottom: 0;
}
.b-blog-card-item__date {
  font: 400 14px/19.6px "Roboto", sans-serif;
  color: #a5a5a5;
  position: absolute;
  bottom: 20px;
  left: 16px;
}
@media screen and (min-width: 768px) {
  .b-blog {
    padding: 80px 24px;
  }
  .b-blog__cards {
    display: flex;
    flex-wrap: wrap;
  }
  .b-blog__title {
    margin-bottom: 32px;
  }
  .b-blog__button {
    margin-top: 21px;
  }
  .b-blog__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .b-news-info__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .b-blog-cards__item {
    margin-right: 24px;
    width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .b-blog-cards__item:nth-child(2n) {
    margin-right: 0;
  }
  .b-blog-card-item__title {
    font: 700 20px/28px "Roboto", sans-serif;
  }
}
@media screen and (min-width: 1280px) {
  .b-blog-cards__item {
    margin-right: 28px;
    width: calc(25% - 21px);
    margin-bottom: 28px;
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s ease;
    -moz-transition: box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  }
  .b-blog-cards__item:hover {
    color: #333;
    -webkit-transition: box-shadow 0.2s ease;
    -moz-transition: box-shadow 0.2s ease;
    -o-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
    box-shadow: 0px 2px 24px rgba(51,51,51,0.12);
  }
  .b-blog-cards__item:nth-child(2n) {
    margin-right: 28px;
  }
  .b-blog-cards__item:nth-child(4n) {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 1920px) {
  .b-blog {
    padding: 121px 20px;
  }
  .b-blog__title {
    margin-bottom: 38px;
  }
  .b-blog__button {
    width: auto;
    padding: 15px 40px;
    margin-top: 34px;
  }
  .b-blog-card-item__info-blog {
    padding: 18px 18px 20px 18px;
  }
  .b-blog-card-item__date {
    left: 20px;
  }
}
.b-news-list {
  padding: 30px 16px 60px 16px;
}
.b-news-list__title {
  margin-bottom: 16px;
}
.b-news-list__button {
  display: block;
  width: 157px;
  padding: 11px 20px;
  font: 700 16px/18px "Montserrat", sans-serif;
  margin-bottom: 24px;
}
.b-news-list__slider {
  display: none;
}
@media screen and (min-width: 768px) {
  .b-news-list {
    padding: 19px 24px 96px 24px;
  }
  .b-news-list__slider {
    display: block;
  }
  .b-news-list__cards {
    display: none;
  }
  .b-news-list__title,
  .b-news-list__button {
    display: inline-block;
    margin-bottom: 31px;
  }
  .b-news-list__button {
    margin-left: 18px;
  }
  .b-news-list-slider__container {
    position: relative;
  }
}
@media screen and (min-width: 1280px) {
  .b-news-list__slider {
    display: none;
  }
  .b-news-list__cards {
    display: block;
  }
  .b-news-list__button {
    padding: 11px 20px;
    font: 700 16px/18px "Montserrat", sans-serif;
  }
  .b-news-list__cards {
    display: flex;
  }
}
@media screen and (min-width: 1920px) {
  .b-news-list {
    padding: 22px 20px 100px 20px;
  }
  .b-news-list__title,
  .b-news-list__button {
    margin-bottom: 39px;
  }
}
.b-video {
  padding: 80px 16px;
}
.b-video__title {
  margin-bottom: 32px;
  text-align: center;
}
.b-video__item-container {
  margin-bottom: 20px;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  height: 0;
  padding-top: 69.55%;
  position: relative;
}
.b-video__item-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.b-video__item-wrapper {
  position: relative;
  display: block;
}
.b-video__item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.b-video__item-play {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  z-index: 50;
  display: block;
  background: url("../img/icons/play.svg") no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.b-video__button {
  margin-top: 32px;
  display: block;
  width: 177px;
  padding: 15px 30px;
  margin-top: 30px;
  font: 700 16px/18px "Montserrat", sans-serif;
}
@media screen and (min-width: 768px) {
  .b-video {
    padding: 80px 24px;
  }
  .b-video__item-play {
    top: calc(50% - 42px);
  }
  .b-video__items {
    display: flex;
    flex-wrap: wrap;
  }
  .b-video__item-wrapper {
    width: calc(50% - 12px);
    margin-right: 24px;
  }
  .b-video__item-wrapper:nth-child(2n) {
    margin-right: 0;
  }
  .b-video__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .b-video__button {
    margin-top: 23px;
  }
  .b-video__title {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1280px) {
  .b-video__item-play {
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
  }
  .b-video__title {
    text-align: center;
    margin-bottom: 38px;
  }
  .b-video__item-wrapper {
    margin-right: 28px;
    width: calc(25% - 21px);
    margin-bottom: 28px;
  }
  .b-video__item-wrapper:hover .b-video__item-container {
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    opacity: 0.9;
  }
  .b-video__item-wrapper:hover .b-video__item-play {
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
    transform: scale(1.2);
  }
  .b-video__item-wrapper:nth-child(2n) {
    margin-right: 28px;
  }
  .b-video__item-wrapper:nth-child(4n) {
    margin-right: 0 !important;
  }
  .b-video__button {
    width: auto;
    padding: 15px 40px;
    margin-top: 14px;
  }
}
@media screen and (min-width: 1920px) {
  .b-video {
    padding: 120px 20px;
  }
}
.b-friends {
  padding: 80px 16px 39px 16px;
}
.b-friends__title {
  text-align: center;
  margin-bottom: 16px;
}
.b-friends__text {
  margin-bottom: 32px;
}
.b-friends__card-item {
  border-radius: 12px;
  margin-bottom: 22px;
  box-shadow: 0px 2px 12px rgba(51,51,51,0.08);
  padding: 20px 16px 8px 16px;
}
.b-friends__boards {
  margin-bottom: 20px;
  border-radius: 5px;
}
.b-friends-card__title {
  font: 700 18px/24px "Roboto", sans-serif;
  margin-bottom: 16px;
}
.b-friends-card__points-container {
  display: flex;
  flex-wrap: wrap;
}
.b-friends-card-points__item {
  font: 400 14px/19.6px "Roboto", sans-serif;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #e1f6fb;
  color: #333 !important;
  margin-bottom: 12px;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  margin-right: 12px;
}
.b-friends-boards__img-container {
  position: relative;
  height: 0;
  padding-bottom: 37%;
}
.b-friends-boards__img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-friends {
    padding: 80px 24px 39px 24px;
  }
  .b-friends__title {
    margin-bottom: 21px;
  }
  .b-friends__card-item {
    padding: 14px 0px 10px 27px;
    margin-bottom: 35px;
  }
  .b-friends__boards {
    margin-bottom: 24px;
    border-radius: 12px;
    overflow: hidden;
  }
  .b-friends-card-points__item {
    padding: 11px 21px;
  }
  .b-friends-boards__img-container {
    padding-bottom: 36%;
  }
}
@media screen and (min-width: 1280px) {
  .b-friends__text {
    padding: 0 340px;
  }
  .b-friends__cards-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .b-friends__card-item {
    margin-right: 28px;
    width: calc(33.333% - 45.6666px);
  }
  .b-friends__card-item:nth-child(3n) {
    margin-right: 0;
  }
  .b-friends__boards-container {
    display: flex;
    padding: 0 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
  }
  .b-friends__boards {
    width: calc(50% - 14px);
    margin-right: 28px;
  }
  .b-friends__boards:nth-child(2n) {
    margin-right: 0;
  }
  .b-friends-card-points__item {
    -webkit-transition: background 0.3s ease;
    -moz-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }
  .b-friends-card-points__item:hover {
    background: #c3eef9;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    transition: background 0.2s ease;
  }
}
@media screen and (min-width: 1920px) {
  .b-friends {
    padding: 120px 0 39px 0;
  }
  .b-friends__text {
    padding: 0px 344px;
    margin-bottom: 39px;
  }
  .b-friends__card-item {
    padding: 24px 0px 10px 18px;
    width: calc(33.333% - 36.6666px);
  }
  .b-friends__boards-container {
    margin-top: 27px;
  }
  .b-friends-card__title {
    font: 700 20px/28px "Roboto", sans-serif;
  }
  .b-friends-card-points__item {
    font: 400 16px/18px "Roboto", sans-serif;
  }
}
.b-parthners {
  padding: 61px 25px;
}
.b-parthners__title {
  font: 700 24px/36px "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 29px;
}
.b-parthners__items-container {
  display: flex;
  flex-wrap: wrap;
}
.b-parthners__img-container {
  max-height: 70px;
  width: calc(33.3% - 10px);
  margin-right: 15px;
  margin-bottom: 16px;
}
.b-parthners__img-container:nth-child(3n) {
  margin-right: 0;
}
.b-parthners__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 120px;
}
@media screen and (min-width: 768px) {
  .b-parthners {
    padding: 63px 74px 44px 73px;
  }
  .b-parthners__title {
    margin-bottom: 31px;
    font: 700 30px/36px "Montserrat", sans-serif;
  }
  .b-parthners__items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .b-parthners__img-container {
    width: calc(20% - 21.6px);
    margin-bottom: 27px;
    margin-left: 12px;
    max-height: 70px;
    display: flex;
    justify-content: center;
    margin-right: 12px;
  }
  .b-parthners__img-container:nth-child(3n) {
    margin-right: 24px;
  }
  .b-parthners__img-container:nth-child(5n) {
    margin-right: 0 !important;
  }
  .b-parthners__img-container:nth-child(5n+1) {
    margin-left: 0 !important;
  }
  .b-parthners__img {
    width: calc(100% - 20px);
  }
}
@media screen and (min-width: 1280px) {
  .b-parthners__title {
    margin-bottom: 48px;
    font: 700 36px/44px "Montserrat", sans-serif;
  }
  .b-parthners__img-container {
    height: auto;
    max-height: 100px;
    max-width: 140px;
  }
}
@media screen and (min-width: 1920px) {
  .b-parthners {
    padding: 143px 20px 96px 20px;
  }
  .b-parthners__img-container {
    margin-right: 0 !important;
    width: calc(11.1% - 10.66666px);
  }
  .b-parthners__img-container:nth-child(5n+1) {
    margin-left: 12px !important;
  }
  .b-parthners__img-container:first-child {
    margin-left: 0px !important;
  }
}
/*# sourceMappingURL=src/styl/index.css.map */