.b-blog, .b-news-info
  padding:  80px 16px
  &__title
    text-align: center
    margin-bottom: 25px
  &__button
    display: block
    width: 177px
    padding: 15px 30px
    margin-top: 34px
    font: 700 16px/18px "Montserrat", sans-serif


.b-blog-cards
  &__item
    overflow hidden
    display: block
    position relative
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
    border-radius: 12px
    margin-bottom: 20px
.b-blog-card-item
  &__img-container
    position relative
    height 0
    padding-top: 69.515%
    &--mini
      padding-top: 56.5%;
  &__img
    position:  absolute
    top: 0
    left: 0
    width: 100%
    object-fit: cover;
    height:  100%
  &__info-blog
    padding: 16px 16px 20px 16px
  &__title
    color cMainBlack
    font: 700 18px/24px "Roboto", sans-serif
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
    overflow: hidden
    margin-bottom:  44px
    margin-bottom: 0;
  &__date
    font: 400 14px/19.6px "Roboto", sans-serif
    color cTextGray
    position: absolute
    bottom 20px
    left 16px

@media screen and (min-width: tWidth)
  .b-blog
    padding:  80px 24px
    &__cards
      display: flex
      flex-wrap wrap
    &__title
      margin-bottom: 32px
    &__button
      margin-top: 21px
    &__button-container
      width: 100%
      display: flex
      justify-content: center
  .b-news-info
    &__cards
      display: flex
      flex-wrap wrap
      justify-content: center;
  .b-blog-cards
    &__item
      margin-right: 24px
      width: calc(50% - 12px)
      margin-bottom 24px
      &:nth-child(2n)
        margin-right: 0
  .b-blog-card-item
    &__title
      font: 700 20px/28px "Roboto", sans-serif
    &__info-blog
      14px 20px 20px 20px
@media screen and (min-width: dWidth)
  .b-blog-cards
    &__item
      margin-right: 28px
      width: calc(25% - 21px)
      margin-bottom 28px
      cursor pointer
      transition: box-shadow .3s ease
      box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
      &:hover
        color cMainBlack
        transition: box-shadow .2s ease
        box-shadow: 0px 2px 24px rgba(51, 51, 51, .12)
      &:nth-child(2n)
        margin-right: 28px
      &:nth-child(4n)
        margin-right: 0!important

@media screen and (min-width: wWidth)
  .b-blog
    padding:  121px 20px
    &__title
      margin-bottom: 38px
    &__button
      width: auto
      padding: 15px 40px
      margin-top: 34px
  .b-blog-card-item
    &__info-blog
      padding: 18px 18px 20px 18px
    &__date
      left 20px