.b-header
  width 100%
  height 72px
  min-width: 320px
  display flex
  padding 16px 18px 16px 16px
  align-items center
  position fixed
  top 0
  left 0
  background cWhiteFon
  box-sizing border-box
  box-shadow (0px 2px 12px rgba(0, 0, 0, 0.12));
  transition: box-shadow  .3s ease
  z-index 100
  &._open-menu
      box-shadow none
  &._scrolling
    box-shadow (0px 2px 12px rgba(0, 0, 0, 0.12))!important
  &__wrapper
    align-items center
    display flex
    width: 100%
  &__logo
    min-width 43px
    height 40px
    margin-right 13px
    background url('../img/logo.png ') no-repeat
  &__text
    color cMainBlack!important
    font: 700 16px/18px "Montserrat", sans-serif
    text-transform uppercase
    white-space: nowrap
  &__desktop-menu
    display none
  &__icons-container
    display flex
    margin-left auto
    margin-bottom 4px

    
@media screen and (min-width: tWidth)
  .b-header
    height 80px
    padding 24px 27px 23px 23px
    &__text
      font: 700 24px/33.6px "Montserrat", sans-serif
    &__icons-container
      align-items: center

@media screen and (min-width: dWidth)
  .b-header
    &__desktop-menu
      display flex
      align-items: center;
      margin-left 20px
      margin-right: 34px
    &__logo
      margin: 0
      padding-right: 11.5px

@media screen and (min-width: wWidth)
  .b-header
    width:  100%
    box-sizing  border-box
    &__wrapper
      max-width  1460px
      margin 0 auto
    &__desktop-menu
      margin-left: 109px
      margin-right 37px
      