@font-face {
  font-family: 'Roboto';
  src:url('../fonts/Roboto-Regular.woff') format('woff'),
      url('../fonts/Roboto-Regular.woff2') format('woff2')
  font-style normal
  font-weight normal
}

@font-face {
  font-family: 'Roboto';
  src:url('../fonts/Lato-Medium.woff') format('woff'),
      url('../fonts/Lato-Medium.woff2') format('woff2')
  font-style normal
  font-weight 600
}

@font-face {
  font-family: 'Roboto';
  src:url('../fonts/Roboto-Bold.woff') format('woff'),
      url('../fonts/Roboto-Bold.woff2') format('woff2')
  font-style normal
  font-weight 700
}

@font-face {
  font-family: 'Roboto';
  src:url('../fonts/Lato-Black.ttf') format('ttf'),
      url('../fonts/Lato-Black.woff') format('woff'),
      url('../fonts/Lato-Black.woff2') format('woff2')
  font-style normal
  font-weight 900
}


@font-face {
  font-family: 'Montserrat';
  src:url('../fonts/Montserrat-Regular.woff') format('woff'),
      url('../fonts/Montserrat-Regular.woff2') format('woff2')
  font-style normal
  font-weight normal
}

@font-face {
  font-family: 'Montserrat';
  src:url('../fonts/Montserrat-Bold.woff') format('woff'),
      url('../fonts/Montserrat-Bold.woff2') format('woff2')
  font-style normal
  font-weight 700
}

@font-face {
  font-family: 'Montserrat';
  src:url('../fonts/Montserrat-Medium.woff') format('woff'),
      url('../fonts/Montserrat-Medium.woff2') format('woff2')
  font-style normal
  font-weight 500
}


@font-face {
  font-family: 'Verdana';
  src:url('../fonts/Verdana.woff') format('woff'),
      url('../fonts/Verdana.woff2') format('woff2')
  font-style normal
  font-weight normal
}
