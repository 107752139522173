@media screen and (min-width: dWidth)
  .b-header-desktop-menu
    &__submenu-wrapper
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
      background-color:  cWhiteFon
      margin-top: 40px
      padding: 8px 20px
      border-radius: 7px
    &__title-container
      display: flex
      align-items: center;
      cursor pointer
    &__submenu
      visibility: hidden
      flex-direction column
      position absolute
      opacity 0
      min-width:100%
      transition: opacity 0.15s ease , visibility 0.15s ease
      top 22px
    &__item
      position relative
      color cMainBlack
      display flex
      align-items flex-start
      margin-right: 5px;
      flex-direction column
      &:hover
        .b-header-desktop-menu__link
          transition color .2s ease
          color cGreenHover!important
        .b-header-desktop-menu__arrow
          transition fill .2s ease, transform .2s ease
          transform: rotate(180deg)
          transform-origin: center center
          fill: cGreenHover;
        .b-header-desktop-menu__submenu
          transition: opacity 0.2s ease , visibility 0.2s ease
          opacity  1
          visibility: visible
    &__link
      cursor pointer
      display flex
      align-items: center
      padding 5px 5px
      font: 500 15px/20px "Montserrat", sans-serif
      transition color .2s ease
      color cMainBlack!important
      &:focus:active
        color arrowGreenActive!important
        .b-header-desktop-menu__arrow
          transform-origin: center center
          transition opacity .15s ease,transform .15s ease
          transform: rotate(180deg)
          transform-origin: center center
          fill: arrowGreenActive
      &:focus 
        color arrowGreenHover!important
        .b-header-desktop-menu__arrow
          transform-origin: center center
          transition opacity .15s ease,transform .15s ease
          transform: rotate(180deg)
          transform-origin: center center
          fill: arrowGreenHover
    &__arrow-container
      margin-left 10px
  .b-header-desktop-submenu
    &__item
      padding 12px 0px
      border-bottom 1px solid cBorder
      white-space: nowrap
      background-color cWhiteFon
      color cMainBlack!important
      transition color .15s ease
      &:hover
        color cGreenHover!important
        transition color .2s ease
      &--active
        color cGreenActive
        transition color .2s ease
      &:last-child
        border-bottom none

  .b-header-desktop-submenu
    &__item
      padding 12px 21px 12px 0px
      border-bottom 1px solid cBorder
      white-space: nowrap
      background-color cWhiteFon
      &:last-child
        border-bottom none

@media screen and (min-width: wWidth)
  .b-header-desktop-menu
    &__item
      margin-right: 19px;
      &._special
          margin-right: 14px 
    &__link
      margin-right: 5px
      font: 500 16px/20px "Montserrat", sans-serif