.b-main-banner
  padding 32px 16px 80px 16px
  &__socials-container
    display: flex
    align-items: center
    margin-left:  5px
  &__slider
    margin-bottom: 20px
    width: 100%
  &__slider-button 
    display flex
  &__muilty
    display: flex
    justify-content: space-between
    flex-wrap: wrap
.b-main-info
  &__title
    font: 700 36px/44px "Montserrat", sans-serif
    margin-bottom 32px
    text-transform: uppercase
  &__text
    font: 400 18px/24px "Roboto", sans-serif
    margin-bottom 44px
  &__button
    margin-bottom 12px
    display block
  &__button-container
    margin-bottom 44px
.banner-slider
    &__items-container
      display: flex
    &__item
      background-color: cWhiteFon
      overflow: hidden      
      box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.1)
      border-radius: 12px
.banner-slider-item
  &__link
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  &__img-container
    height: 0
    padding-top 85.37%
    overflow: hidden
    position relative
  &__img
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit: cover;
  &__data-container
    color cMainBlack
    padding:  16px 16px 56px 16px
    position: relative
  &__title
    font: 700 18px/24px "Roboto", sans-serif
    margin-bottom: 12px
    display: -webkit-box
    -webkit-line-clamp: 5
    -webkit-box-orient: vertical
    overflow: hidden
  &__text
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
    overflow: hidden
    font: 400 16px/22.4px "Roboto", sans-serif
  &__date
    font: 400 14px/19.6px "Roboto", sans-serif
    color cTextGray
    margin-top: 16px
    position: absolute
    bottom:  20px
    left 16px

.banner-socials-container
  &__item-img
    fill cImgBlue
    transition: fill .3s ease
.banner-slider-button
  &__item-img
    width: 12px
    height: 12px
    background: url('../img/icons/arrow.svg') no-repeat
    background-size contain
    &--next
      transform: rotate(180deg)
  &__item
    width: 48px
    height: 48px
    display: flex
    align-items: center
    justify-content: center
    border-radius:  50%
    background-color: cWhiteFon
    filter: drop-shadow(0px 2px 12px rgba(51, 51, 51, 0.08))
    &--active
      filter: drop-shadow(0px 2px 24px rgba(51, 51, 51, 0.12))
    &--disabled  
        opacity .5
    &--prev
      margin-right: 20px

@media screen and (min-width: tWidth)
  .b-main-banner
    padding:  44px 24px 80px 24px
    &__slider
      margin-bottom: 31px
    &__socials-container
      margin-left: 12px
  .b-main-info
    &__button-container
      display: flex
    &__button
        margin-left: 7px
        &--special
          margin-left: 0px
    &__text
      font: 400 20px/32px "Roboto", sans-serif
    &__button-container
      margin-bottom: 70px
  .banner-slider
    &__item
      display:  flex
      height: 300px!important
  .banner-slider-item
    &__img-container
      width: 48.3%
      padding: 0
      height: 300px
    &__img
      position relative
      object-fit: cover
      
    &__data-container
      width: 51.7%
      box-sizing: border-box
      padding: 22px 25px 20px 25px
      height: 300px
    &__date
      position:  absolute
      bottom 20px
  

  .banner-socials-container
    &__item
      margin-right: 8px

@media screen and (min-width: dWidth)
  .b-main-banner
    padding 85px 36px 60px 36px
    display: flex
    &__slider
      overflow: hidden
      margin-bottom: 7px
      padding: 20px 20px 24px 15px;
      margin-right: -20px
    &__info
      margin-right: 40px
      width: calc(50% - 40px)
    &__muilty
      position: relative
      width: 50%
    &__slider-button
      width: 100%
      justify-content: flex-end

    &__socials-container
      position: absolute
      top -45px
      right:  0
  .b-main-info
    &__button
      font-size: 14px 
  .banner-socials-container
    &__item
      cursor pointer
      &:hover
        .banner-socials-container__item-img
          transition: fill .2s ease
          fill cBlueHover
@media screen and (min-width: wWidth)
  .b-main-banner
    padding: 103px 12px 60px 12px
    &__info
      margin-right: 216px
      width: 652px
    &__muilty
      width: 592px
    &__socials-container
      top: -76px
  .banner-slider-item
    cursor: pointer
    &:hover
      transition: box-shadow .2s ease
      box-shadow: 0px 2px 24px rgba(51, 51, 51, 0.12)
      color: cMainBlack
    &__img-container
      width: 73.74%
    &__date
      left 25px

  .b-main-info
    &__title
      font: 700 72px/72px "Montserrat", sans-serif
      margin-bottom: 40px
    &__text
      margin-bottom: 53px
    &__button
      font-size 18px

  .banner-socials-container
    &__item
      margin-right: 12px
      &:last-child
        margin-right: 0
