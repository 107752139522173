.b-news
  padding: 80px 16px 30px 24px
  &__title
    text-align center
    margin-bottom 16px
@media screen and (min-width: tWidth)
  .b-news
    padding: 80px 24px 22px 24px
    &__title
      margin-bottom: 18px

@media screen and (min-width: dWidth)
  .b-news
    &__title
    &__text
      padding: 0 360px
      text-align: center
@media screen and (min-width: wWidth)
  .b-news
    padding 122px 6px 37px 6px