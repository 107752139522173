vendor(prop, args)
    -webkit-{prop}: args
    -moz-{prop}: args
    -o-{prop}: args
    {prop}: args

transition()
    vendor('transition', arguments)

animate(name = fadeIn, duration = 1s, delay = 0.2s, function = ease, mode = both)
    -moz-animation: name duration delay function mode
    -webkit-animation: name duration delay function mode
    animation: name duration delay function mode

inline-block(val = middle)
    display: inline-block
    vertical-align: val

middle(val = middle)
    content: ''
    width: 0
    height: 100%
    inline-block: val

clear()
    width: 100%
    clear: both

clearfix()
    &:before, &:after
        content: ''
        display: table

    &:after
        clear: both

.clearfix
    clearfix()

placeholder(color = black, fontStyle = normal)
    &::-webkit-input-placeholder
        color: color
        opacity: 1
        font-style: fontStyle

    &:-moz-placeholder
        color: color
        opacity: 1
        font-style: fontStyle

    &::-moz-placeholder
        color: color
        opacity: 1
        font-style: fontStyle

    &:-ms-input-placeholder
        color: color
        opacity: 1
        font-style: fontStyle

triangle-base()
    display: block
    width: 0
    height: 0
    content: ''

triangle(direction, size, color)
    if (direction == up)
        triangle-base()
        border-left: (size / 2) solid transparent
        border-right: (size / 2) solid transparent
        border-bottom: height solid color
        border-top: none

    if (direction == down)
        triangle-base()
        border-left: (size / 2) solid transparent
        border-right: (size / 2) solid transparent
        border-top: height solid color
        border-bottom: none

    if (direction == left)
        triangle-base()
        border-top: (size / 2) solid transparent
        border-bottom: (size / 2) solid transparent
        border-right: height solid color
        border-left: none

    if (direction == right)
        triangle-base()
        border-top: (size / 2) solid transparent
        border-bottom: (size / 2) solid transparent
        border-left: height solid color
        border-right: none

bg(width, height, url)
    background: url('../img/' + url) 50% 50% no-repeat
    background-size: cover
    width: width
    height: height

