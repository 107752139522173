.b-friends
  padding: 80px 16px 39px 16px
  &__title
    text-align: center
    margin-bottom: 16px
  &__text
    margin-bottom: 32px
  &__card-item 
    border-radius: 12px
    margin-bottom: 22px
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
    padding: 20px 16px 8px 16px
  &__boards
    margin-bottom 20px
    border-radius: 5px;
.b-friends-card
    &__title
      font: 700 18px/24px "Roboto", sans-serif
      margin-bottom 16px
    &__points-container
      display:  flex
      flex-wrap: wrap
.b-friends-card-points
  &__item
    font: 400 14px/19.6px "Roboto", sans-serif
    padding 8px 12px
    border-radius: 6px
    background-color: cButBlue
    color:  cMainBlack!important
    margin-bottom: 12px
    transition: background .3s ease
    margin-right: 12px


.b-friends-boards
    &__img-container
      position relative
      height 0
      padding-bottom: 37%
    &__img
      position absolute
      object-fit: cover
      top 0
      left 0
      height 100%
      width 100%
@media screen and (min-width: tWidth)
  .b-friends
    padding: 80px 24px 39px 24px
    &__title
      margin-bottom: 21px
    &__card-item
      padding: 14px 0px 10px 27px
      margin-bottom: 35px
    &__boards 
      margin-bottom: 24px
      border-radius: 12px;
      overflow: hidden
  .b-friends-card-points
    &__item
      padding: 11px 21px
  .b-friends-boards
    &__img-container
      padding-bottom: 36%
@media screen and (min-width: dWidth)
  .b-friends
    &__text
      padding:  0 340px
    &__cards-container
      display: flex
      flex-wrap: wrap
      padding 0 20px
    &__card-item
      margin-right: 28px
      width: calc(33.333% - 45.6666px)
      &:nth-child(3n)
        margin-right: 0
    &__boards-container
      display flex
      padding: 0 20px;
      flex-wrap wrap
      justify-content: center
      margin-top: 40px
    &__boards
      width: calc(50% - 14px)
      margin-right: 28px
      &:nth-child(2n)
          margin-right: 0
  .b-friends-card-points
    &__item
      transition background .3s ease
      &:hover
        background cButBlueHover
        transition background .2s ease
@media screen and (min-width: wWidth)
  .b-friends
    padding: 120px 0 39px 0
    &__text
      padding: 0px 344px
      margin-bottom: 39px
    &__card-item
      padding: 24px 0px 10px 18px
      width: calc(33.333% - 36.6666px)
    &__boards-container
      margin-top: 27px
  .b-friends-card
    &__title
      font: 700 20px/28px "Roboto", sans-serif

  .b-friends-card-points
    &__item
      font: 400 16px/18px "Roboto", sans-serif


