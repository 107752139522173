.text
  h3
    color cMainBlack
    font: 700 24px/36px "Montserrat", sans-serif
  h4
    color cMainBlack
    font: 700 20px/28px "Montserrat", sans-serif
  p
    color cMainBlack
    font: 400 18px/24px "Roboto", sans-serif
    text-align: center

@media screen and (min-width: tWidth)
  .text
    h3
      font: 700 30px/36px "Montserrat", sans-serif

@media screen and (min-width: dWidth)
  .text
    h3
      font: 700 30px/36px "Montserrat", sans-serif

