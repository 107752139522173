.b-mobile-menu
  position fixed
  width 100%
  background cWhite
  z-index 10
  font: 500 18px/24px "Montserrat", sans-serif
  top -90%
  padding 0 16px
  overflow auto
  box-sizing border-box
  max-height: 0
  overflow: hidden
  padding-top 12px
  height calc(100% - 72px)
  transition max-height .3s ease, top .3s ease
  background cWhiteFon
  margin-top 72px
  &._open
    max-height: 900px
    z-index 50
    overflow: auto
    top 0px!important

  &__button
    margin-top 28px
    padding 10px 51px!important
    color cMainBlack!important
  &__item
    &._open
      .b-mobile-menu-item__arrow
        transform: rotate(180deg)
        transform-origin: center center
        transition: transform .3s ease
        fill: cGreenActive
  &__footer
    margin-top 40px
    width: calc(100% + 32px);
    margin-left -16px
    box-sizing border-box
    background-color cMainBlack
    padding 30px 16px 40px 16px
    font: 400 16px/20px "Montserrat", sans-serif

    
@media screen and (min-width: tWidth)
  .b-mobile-menu
    display flex
    height calc(100% - 80px)
    margin-top 80px
    padding 0
    &__button 
      display none!important
    &__wrapper
      overflow hidden
      width: 100%
      display: flex
    &__scrolling-container
      overflow auto
      height: 100%
      width 51.5%
      padding 12px 24px 20px 24px
      box-sizing border-box
    &__footer
      width 48.5%
      margin-left: 0
      margin-top 0
      padding 5px 24px 40px 24px
      .b-footer__contacts
        width 100%
        margin 0
      .b-footer__bottom-block
        margin-top: 42px
        display block
      .b-footer__socials
        margin-top: 39px
      .b-footer-socials__item
        margin-right: 24px