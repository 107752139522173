.b-video
  padding: 80px 16px
  &__title
    margin-bottom 32px
    text-align: center
  &__item-container
    margin-bottom: 20px
    transition: opacity .3s ease
    display flex
    align-items:  center
    justify-content: center
    width: 100% 
    border-radius: 12px
    overflow hidden
    height: 0
    padding-top 69.55%
    position relative
    iframe
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  &__item-wrapper
    position: relative
    display: block
  &__item
    position: absolute
    top 0
    left: 0
    height: 100%
    object-fit: cover
    width 100%
  &__item-play
    position: absolute
    top: calc(50% - 32px)
    left: calc(50% - 32px)
    z-index 50
    display block
    background: url('../img/icons/play.svg') no-repeat
    background-size contain
    width  64px
    height:  64px
    transition: transform .3s ease
  &__button
    margin-top: 32px
    display: block
    width: 177px
    padding: 15px 30px
    margin-top 30px
    font: 700 16px/18px "Montserrat", sans-serif

@media screen and (min-width: tWidth)
  .b-video
    padding 80px 24px
    &__item-play
      top: calc(50% - 42px)
    &__items
      display:  flex
      flex-wrap:  wrap
    &__item-wrapper
      width: calc(50% - 12px)
      margin-right: 24px
      &:nth-child(2n)
        margin-right: 0
    &__button-container
      width: 100%
      display: flex
      justify-content: center
    &__button
      margin-top: 23px
    &__title
      margin-bottom: 32px

@media screen and (min-width: dWidth)
  .b-video
    &__item-play
      transition: transform .3s ease
    &__title
      text-align: center
      margin-bottom: 38px
    &__item-wrapper
      margin-right: 28px
      width: calc(25% - 21px)
      margin-bottom 28px
      &:hover
        .b-video__item-container
          transition: opacity .2s
          opacity: .9
        .b-video__item-play
          transition: transform .2s ease
          transform: scale(1.2)
      &:nth-child(2n)
        margin-right: 28px
      &:nth-child(4n)
        margin-right: 0!important
    &__button
      width: auto
      padding: 15px 40px
      margin-top: 14px
@media screen and (min-width: wWidth)
  .b-video
    padding 120px 20px
    