.news-list table img {
  max-width: 192px;
  max-height: 192px;
  object-fit: cover;
}
.b-project
  padding: 80px 16px 30px 16px
  &__title
    margin-bottom: 16px
    text-align: center
  &__text
    margin-bottom: 32px
  &__bottom-block
    position: relative
    display: flex
    justify-content: space-between
  &__slider
    margin-bottom: 43px
.b-project-slider
  &__items-container
    display: flex
  &__item
    box-shadow: 0px 2px 12px rgba(51, 51, 51, 0.08)
    border-radius: 12px
    height: auto!important
.b-project-slider-item
  &__img-container
    height: 0
    padding-bottom: 70.515%
    position relative
  &__img
    position absolute
    top 0
    left:0
    width: 100%
    height:  100%
    object-fit: cover;
  &__data-container
    color cMainBlack
    padding: 12px 16px 20px 16px
  &__title
    color cMainBlack
    width: 90%
    font: 700 18px/24px "Roboto", sans-serif

.b-project-bottom-block
    &__arrow
      display: flex
    &__button
      padding: 15px 30px
      font: 700 16px/18px "Montserrat", sans-serif

@media screen and (min-width: tWidth)
  .b-project
    padding: 52px 24px 40px 24px
    &__bottom-block
      justify-content: center
    &__slider
      margin-bottom: 45px
  .b-project-bottom-block
    &__arrow
      position:  absolute
      top 0
      right 0
  .b-project-slider-item
    &__title
      width: 100%
    &__data-container
      padding: 15px 16px 20px 19px
@media screen and (min-width: dWidth)
  .b-project
    &__text
      padding: 0 350px
      margin-bottom: 39px
      text-align: center
    &__title
      margin-bottom: 20px
    &__slider
      margin-bottom: 38px
      overflow: hidden
    &__bottom-block
      padding:  0 20px
  .b-project-bottom-block
    &__button
      padding 15px 40px
    &__arrow
      right: 20px

  .b-project-slider
    &__items-container
      padding:  0 20px 24px 20px
    &__item
      cursor pointer
      transition: box-shadow .3s ease
      &:hover
        color cMainBlack
        transition: box-shadow .2s ease
        box-shadow: 0px 2px 24px rgba(51, 51, 51, 0.12)

@media screen and (min-width: wWidth)
  .b-project
    padding: 121px 0 40px 0  