html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
    display: block
strong
    font-weight: bold
em
    font-style: italic
body
    line-height: 1
    font-family: 'Roboto'
ol, ul
    list-style: none
blockquote, q
    quotes: none
blockquote:before, blockquote:after, q:before, q:after
    content: ''
    content: none
table
    border-collapse: collapse
    border-spacing: 0
img
    border: none
    -ms-interpolation-mode: bicubic
textarea
    overflow: auto
    resize: vertical
button
    width: auto
    overflow: visible
    cursor: pointer
button::-moz-focus-inner
    padding: 0
    border: 0
*
    outline: none
    box-sizing border-box
input, textarea, select
    -webkit-appearance: none
    -moz-appearance: none
    -o-appearance: none
    -ms-appearance: none
    appearance: none
input[type="search"]::-webkit-search-cancel-button
    display: none
input::-ms-clear
    display: none
#mfPreviewBar
    display: none !important
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

a
    text-decoration none

